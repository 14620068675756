.login {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: aliceblue;
    width: auto;
    padding: 2%;
    text-align: center;
}

.login-table {
    border-style: none;
    font-size: 0.5cm;
}

.login-input {
    background-color: white;
    border-style: none;
    height: 0.5cm;
    font-size: 0.5cm;
}

.login-button {
    height: auto;
    width: 100%;
    background-color: white;
    border-style: none;
    font-size: 0.5cm;
    color: black;
}

.login-button:hover {
    background-color: rgb(210, 234, 255);
    border-color: black;
    transition: 500ms;
}
