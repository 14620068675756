.hidden {
  display: none
}

.centered {
  text-align: center;
}

.symbol-button-red {
  width: 25px;
  color: white;
  font-weight: 900;
  background-color: red;
  border-radius: 20%;
  border-style: solid;
  border-color: darkred;
  text-align: center;
}

.button-red {
  width: auto;
  color: white;
  font-weight: 900;
  background-color: red;
  border-style: solid;
  border-color: darkred;
  text-align: center;
}

.symbol-button-red:hover {
  background-color: rgb(255, 80, 80);
  transition: 500ms;
}

.button-red:hover {
  background-color: rgb(255, 80, 80);
  transition: 500ms;
}

.symbol-button-green {
  width: 25px;
  color: white;
  font-weight: 900;
  background-color: green;
  border-radius: 20%;
  border-style: solid;
  border-color: darkgreen;
  text-align: center;
}

.symbol-button-green:hover {
  background-color: rgb(0, 170, 0);
  transition: 500ms;
}

.symbol-button-blue {
  width: 25px;
  color: white;
  font-weight: 900;
  background-color: blue;
  border-radius: 20%;
  border-style: solid;
  border-color: darkblue;
  text-align: center;
}

.symbol-button-blue:hover {
  background-color: rgb(92, 92, 255);
  transition: 500ms;
}

.symbol-button-orange {
  width: 25px;
  color: white;
  font-weight: 900;
  background-color: darkorange;
  border-radius: 20%;
  border-style: solid;
  border-color: rgb(197, 108, 0);
  text-align: center;
}

.symbol-button-orange:hover {
  background-color: orange;
  transition: 500ms;
}

.button-orange {
  width: auto;
  color: white;
  font-weight: 900;
  background-color: darkorange;
  border-style: solid;
  border-color: rgb(197, 108, 0);
  text-align: center;
}

.button-orange:hover {
  background-color: orange;
  transition: 500ms;
}

.todo-table {
  border-color: rgb(25, 148, 255);
  border-width: 5px;
  border-collapse: collapse;
  margin-top: 20px;
  width: 100%;
}

.todo-table th, .todo-table td {
  border: 2px solid black;
  padding: 10px;
  text-align: center;
  border-left: 0px;
  border-right: 0px;
  border-top-color: rgb(25, 148, 255);
  border-bottom-color: rgb(25, 148, 255);
}

.todo-table th {
  background-color: aliceblue;
}

.todo-table tr:hover {
  background-color: rgb(250, 253, 255);
  transition: 500ms;
}

.left-align {
  text-align: left;
}

#popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: aliceblue;
  border-style: solid;
  border-color: black;
  width: 70%;
  text-align: center;
}

#editPopup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: aliceblue;
  border-style: solid;
  border-color: black;
  width: 70%;
  text-align: center;
}

.nearlyFullWidth {
  width: 95%;
}

table tr, table td {
  border-width: 0px;
  border-left: 0px;
  border-right: 0px;
}

.newItemTable {
  background-color: aliceblue;
  border-width: 2px;
  border-color: rgb(25, 148, 255);
  width: 100%;
}

.updateTable {
  background-color: aliceblue;
  border-width: 0px;
  width: 100%;
}

.list-locked-msg {
  color: darkred;
  font-weight: 800;
}
