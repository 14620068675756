.header {
    display: flex;
    justify-content: space-between;
    padding: 0.5%;
    background-color: aliceblue;
    border-bottom: 3px;
    border-bottom-color: rgb(25, 148, 255);
    border-bottom-style: solid;
}

.logout-button {
    width: auto;
    border-radius: 30%;
    font-size: large;
    font-weight: 700;
    color: white;
    background-color: rgb(25, 148, 255);
    border-width: 0px;
}

.logout-button:hover {
    background-color: rgb(0, 136, 255);
    transition: 500ms
}
